import client from '../../sanityClient'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'

const fluidProps = ({ imgRef, maxWidth = 1920 } ) => {
  return getFluidGatsbyImage(
    imgRef,
    {maxWidth: maxWidth},
    client.clientConfig
  )
}

export default fluidProps
