import { useEffect } from 'react';

function useRealHeight () {

  const setHeight = () => {
    if (typeof window === "undefined" || !window.document) return null

    const doc = document.documentElement
    doc.style.setProperty('--full-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    return () => { //Removes event when unmounted
      window.removeEventListener('resize', setHeight);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  setHeight();
}

export default useRealHeight;